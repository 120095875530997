.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.header,
.footer {
    background-color: var(--background);
    color: var(--text-primary);
    padding: 1rem;
    text-align: center;
}

.main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}