.custom-button {
  background-color: var(--primary); /* Use primary color for button */
  color: var(--text-primary); /* Use primary text color */
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Slightly darker shadow for better contrast */
  transition: background-color 0.5s, color 0.5s; /* Smooth transition for background and text color */
}

.custom-button:hover {
  background-color: var(--primary-dark); /* Use a lighter primary color for hover effect */
  color: var(--text-secondary); /* Use secondary text color for hover effect */
}