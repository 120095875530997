.home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--background);
    color: var(--text-primary);
    padding: 1rem;
}

.intro-section,
.main-section {
    text-align: center;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
}

.greeting-text {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.intro-text {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    max-width: 600px;
    margin: 0 auto;
}

.question-text {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
}

.button-group {
    display: flex;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;
}
