.game-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--background);
  color: var(--text-primary);
}

.game-window {
  border: 2px solid rgb(247, 0, 0);
  overflow: hidden;
  margin-top: 1rem;
}
