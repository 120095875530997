:root {
  --background: #121212;
  --text-primary: #dcdcdc;
  --text-secondary: #a8a8a8;
  --primary: #2c3e50;
  --primary-light: #3a5068;
  --secondary: #8e44ad;
  --accent: #5d6d7e;
  --error: #e74c3c; 
  --success: #27ae60;
}