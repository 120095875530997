.aboutMeContainer {
  background-color: var(--background);
  padding: 2rem;
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
}

.aboutMeHeading {
  color: var(--text-primary);
  margin-bottom: 1rem;
}

.aboutMeText {
  color: var(--text-secondary);
  line-height: 1.5;
  margin-bottom: 1.5rem;
}
